import React from 'react';

const LoadingPage = () => {
	return (
			<div className="loading_big_div">
				<div className="loader"/>
			</div>
	);
}

export default LoadingPage;
