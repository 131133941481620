import React from 'react';

const MapCoins = ()=>{
    return(
        <div id='map-coins' >
            <h1 className='text-center'>Coins on a Map</h1>
            <h2 className='text-center mt-5 pt-5'>Coming Soon ...</h2>
        </div>
    )
}
export default MapCoins
