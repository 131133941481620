import React from 'react';
const ErrorPage = ()=>{
    return(
        <div id='error-page'>
            <center>
                <h1>404</h1>
                <h2>Sorry, the page you visited does not exist.</h2>
            </center>

        </div>
    )
}
export default ErrorPage
